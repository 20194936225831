import React from "react";
import { CellRenderFunction } from "@getregistered/greg-editors/";
import { CellRenderer } from "@getregistered/greg-editors";
import { RegistrationCheckPoint } from "./types";
import { CheckinCell } from "./CheckinCell";

export const renderCheckinCell: CellRenderFunction = ({ row, column }) => {
  if (column.type === "checkpoint") {
    const checkPoint = row[column.key] as RegistrationCheckPoint;
    if (checkPoint.attending) {
      return (
        <CheckinCell
          key={[row.id, column.key].join("-")}
          checkPoint={checkPoint}
        />
      );
    } else {
      return <td>NOT ATTENDING</td>;
    }
  } else {
    return <CellRenderer row={row} column={column} />;
  }
};

import React, { FC, useEffect, useState } from "react";

import railsFetch from "../../railsFetch";
import { RegistrationCheckPoint } from "./types";

export interface CheckinCellProps {
  checkPoint: RegistrationCheckPoint;
}

export const CheckinCell: FC<CheckinCellProps> = ({
  checkPoint: initialCheckpoint,
}) => {
  const [checkPoint, setCheckPoint] =
    useState<RegistrationCheckPoint>(initialCheckpoint);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => setCheckPoint(initialCheckpoint), [initialCheckpoint]);

  const handleChange = async () => {
    if (loading) return;
    try {
      await railsFetch(checkPoint.url, {
        method: checkPoint.arrived ? "DELETE" : "PUT",
      });
      setCheckPoint({ ...checkPoint, arrived: !checkPoint.arrived });
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const classes = checkPoint.arrived ? "button danger" : "button action";
  const text = checkPoint.arrived ? "Check out" : "Check in";
  return (
    <td>
      <div className="button-group">
        <button
          type="button"
          className={classes}
          disabled={loading}
          onClick={handleChange}
        >
          {text}
        </button>
      </div>
    </td>
  );
};
